import domLoaded from 'dom-loaded';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';
import * as nTracking from '@financial-times/n-tracking';
import { displayAds, getAdsData, adsUtils } from '@financial-times/ads-display';
import * as nSyndication from '@financial-times/n-syndication';
import replaceWithFlourish from './components/flourish/replace-graphics';
import * as myftUi from '@financial-times/n-myft-ui/myft';
import * as myftInstantAlert from '@financial-times/n-myft-ui/components/instant-alert';
import { utils } from '@financial-times/community-event-teaser';

import stream from './components/stream/main';
import * as nExponea from '@financial-times/n-exponea';
import workspaceNavInit from '@financial-times/workspace-menu';

domLoaded.then(async () => {
	const flags = pageKitFlags.init();
	const appContextClient = pageKitAppContext.init();
	const appContext = appContextClient.getAll();

	const headerOptions = flags.get('enhancedSearchTest')
		? { enhancedSearchUrl: 'https://enhanced-search.ft.com/?q=', searchState: 'open' }
		: {};

	pageKitLayout.init({ headerOptions });

	if (flags.get('oTracking')) {
		// Additional information about
		const metaData = document.getElementById('stream-page-meta');
		const extraContext = metaData ? JSON.parse(metaData.innerHTML) : {};

		const oTracking = nTracking.init({ appContext, extraContext });

		// Send an event when an audio teaser enters the viewport. Used to calculate click-through-rate.
		// Speak to Luke K or the audio team
		oTracking.view.init({
			selector: '.o-teaser--audio',
			getContextData: (el) => {
				return {
					componentContentId: el.getAttribute('data-id'),
					component: {
						id: el.getAttribute('data-id'),
						name: 'teaser',
						type: 'audio',
						subtype: 'podcast', // only podcast is audio subtype at 03/2019. Need to change when audio has more subtypes.
					},
				};
			},
		});

		if (flags.get('realUserMonitoringForPerformance')) {
			nTracking.trackers.realUserMonitoringForPerformance();
			nTracking.trackers.realUserMonitoringWithAttribution();
		}

		if (flags.get('eventsInStreamPage')) {
			utils.initTeaserTracking(oTracking);
		}
	}

	if (flags.get('useFlourish')) {
		replaceWithFlourish(appContext);
	}

	if (flags.get('ads')) {
		const initAds = async ({ appContextClient, flags }) => {
			const rootId = adsUtils.getRootID();
			const displayAdsOptions = {
				appName: appContextClient.get('appName'),
				abTestState: appContextClient.get('abTestState'),
				rootId,
				lazyLoadMargins: {
					980: '15%',
					760: '5%',
				},
				waitForIas: flags.get('ads-validate-traffic-ias')
			};

			// Fetch the ads data
			let adsData;
			try {
				adsData = await getAdsData({
					user: true,
					page: {
						type: 'stream',
						id: appContextClient.get('conceptId'),
					},
				});
			} catch (e) {
				console.warn('There was an error fetching the ads data.', e); //eslint-disable-line no-console
			}

			// Enable ads
			try {
				await displayAds.init(
					{
						...displayAdsOptions,
						targeting: adsData.metadata,
						adUnit: adsData.adUnit,
					},
					flags
				);

				const oAds = window.oAds;
				oAds.utils.on('slotExpand', (event) => {
					const slotEl = event.detail;
					if (!slotEl || slotEl.pos !== 'native' || slotEl.isEmpty === false)
						return;

					// Look back up the DOM tree for the slot's Section container to hide it
					// and all its children (i.e. title, border, content, etc)
					const containerEl = slotEl.slot.container.closest(
						'[data-trackable="section-promoted-content"]'
					);
					if (containerEl) {
						containerEl.style.display = 'none';
					}
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.warn(
					'Failed to load targeted ads. Falling back to loading basic ads without targeting or ad unit'
				); //eslint-disable-line no-console
				await displayAds.init(displayAdsOptions, flags);
			}

			// Enable permutive
			if (flags.get('AdsPermutive')) {
				try {
					await adsUtils.enablePermutiveFtCom({
						metadata: adsData.metadata,
						type: appContextClient.get('appName'),
						rootId,
					});
				} catch (e) {
					// eslint-disable-next-line no-console
					console.warn(
						'Failed to load permutive correctly. Falling back to loading permutive without page or user data'
					); //eslint-disable-line no-console
					await adsUtils.enablePermutiveFtCom({
						type: appContextClient.get('appName'),
						rootId,
					});
				}
			}
		};

		initAds({ appContextClient, flags });
		if (flags.get('AdsPermutive')) {
			document.addEventListener('oCookieMessage.act', () => {
				setTimeout(async () => {
					await adsUtils.updateConsentInfo({
						updatePrivacyLegislation: false,
						updateGpcValue: false,
						updateConsentCookie: true,
					});
					await initAds({ appContextClient, flags });
				}, 1000);
			});
		}
	}

	if (flags.get('syndication')) {
		nSyndication.init(flags);
	}

	const clientOpts = [];

	if (flags.get('myFtApi')) {
		clientOpts.push({ relationship: 'followed', type: 'concept' });
		clientOpts.push({ relationship: 'saved', type: 'content' });
	}

	myftUi.client.init(clientOpts).catch((e) => {
		// Keeps console clean for anonymous users
		if (e !== 'No session cookie found') {
			throw e;
		}
	});

	myftUi.ui.init({
		anonymous: !/FTSession_s=/.test(document.cookie),
		flags,
	});

	myftInstantAlert.init({
		anonymous: !/FTSession_s=/.test(document.cookie),
		flags,
	});

	if (nExponea.canShowExponea(flags)) {
		try {
			nExponea.init();
		} catch (error) {
			console.error('Error initialising Exponea SDK', error); //eslint-disable-line no-console
		}
	}

	/**
	 * Initialise the client side Workspace menu with tooltip message for main navigation (B2B users only).
	 * Team: LifeCycle - Enterprise
	 */
	workspaceNavInit({
		app: appContext.appName || 'stream-page',
		enabled: !flags.get('myFtWorkspaceTest') && flags.get('enterpriseWorkspaceNav'),
		navTarget: '[data-component="nav-list--right"]',
		drawerTarget: '[data-component="drawer-menu--primary__drawer-menu-list"]',
		drawerDividerTarget: '[data-component="drawer-menu-item--divide"]',
	}).catch((error) => {
		// eslint-disable-next-line no-console
		console.error(
			'Workspace navigation failed to initialise, please inform LifeCycle team in Enterprise',
			error
		);
	});

	stream.init();

});
